.tabrow {
  display: table;
  text-align: left;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: var(--work-package-details--tab-height) - 10px;
  height:      var(--work-package-details--tab-height);
  overflow: hidden;
  position: relative;
  width: 100%;
  font-weight: bold;
  text-transform: uppercase;

  li {
    display: table-cell;
    box-sizing: content-box;
    position: relative;
    line-height: 38px;
    margin: 0;
    padding: 0;
    text-align: center;
    cursor: pointer;

    .wp-tabs-count {
      margin-left: 5px;
      border-radius: 0.5rem;
      min-width: 1rem;
      display: inline-block;
      font-size: 0.7rem;
      line-height: 1rem;
      min-height: 1rem;
      vertical-align: 0.1rem;
      padding: 0 0.15rem 0 0.15rem;
      background: var(--light-gray);
      font-weight: bold; } }

  a {
    display: inline-block;
    color: #333;
    text-decoration: none;
    &:hover {
      text-decoration: none; } }

  li.tab-icon {
    width: 5%;
    .icon-context:before {
      padding-top: 10px; }
    &:hover {
      border-bottom: 0; } }
  li.selected,
  li:hover {
    color: #999;
    border-bottom: 2px solid;
    a {
      color: var(--content-link-color); }
    .wp-tabs-count {
      color: var(--content-link-color);
      &:hover {
        color: var(--content-link-color); } } }
  li.selected,
  li.selected:hover {
    border-bottom-color: var(--content-link-color); }
  li:hover {
    border-bottom-color: #DDDDDD; }

  li.-disabled {
    cursor: default;
    pointer-events: none;
    border-bottom-width: 0;
    a {
      color: var(--tabs-font-color-disabled); } } }

content-tabs {
  min-height: 73px;
  display: block; }

.scrollable-tabs {
  position: relative;
  overflow: hidden;
  box-sizing: content-box;
  height: 40px;
  border-bottom: 1px solid #DDDDDD;
  margin-bottom: 1rem;

  .tabrow {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    height: 57px;
    li {
      display: inline-block;
      padding-left: 1rem;
      padding-right: 1rem;
      font-size: 14px; } }

  &.-narrow {
    margin-bottom: 0;
    height: initial;

    .tabrow {
      height: initial;
      line-height: initial;

      li {
        line-height: initial;
        padding-bottom: 6px; } } } }

.scrollable-tabs--button {
  display: block;
  width: 20px;
  position: absolute;
  top: 0px;
  line-height: 38px;
  background: white;
  color: var(--light-gray);
  font-size: 0.7rem;
  &.-left {
    left: 0;
    box-shadow: 0px 0px 10px 10px white;
    text-align: left; }
  &.-right {
    right: 0;
    text-align: right;
    box-shadow: 0px 0px 10px 10px white; }
  &:hover {
    color: var(--content-link-color);
    cursor: pointer; } }

.tab-content {
  .form--section {
    padding: 0; } }
