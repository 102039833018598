// In Safari custom elements have width and height 0 per default
// That is why position: sticky in its children won't work
wp-timeline-header {
  display: block;
  height: var(--generic-table--header-height);
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 2; }

.wp-timeline--header-element {
  background: white;
  position: absolute;
  height: 10px;
  width: 10px;
  top: 0;
  left: 0;
  line-height: normal;
  border-right: 1px solid var(--timeline--header-border-color);
  text-align: center;
  font-size: 8px;
  // Clip overflow text to avoid overlapping or line breaking
  text-overflow: clip;
  white-space: nowrap;

  &.-top-border {
    border-top: 1px solid var(--timeline--header-border-color); } }

.wp-timeline--header-day-element {
  height: 12px;
  padding-top: 1px;
  border-bottom: 1px solid var(--timeline--header-border-color); }

.wp-timeline--header-middle-element {
  border-top: 1px solid var(--timeline--header-border-color);
  border-bottom: 1px solid var(--timeline--header-border-color);
  padding-top: 5px;
  height: 20px; }

.wp-timeline--header-top-bold-element {
  border-top: 1px solid var(--timeline--header-border-color);
  font-weight: bold;
  font-size: 11px;
  height: 15px; }
