.op-uc-toc {
  break-inside: avoid;
  font-size: var(--wiki-toc-ul-font-size);
  background-color: #fafafa;
  border: 1px solid #dadada;
  float: right;
  margin: 1rem 0rem 1rem 2rem;
  padding: 1.5rem 1.5rem 1.5rem 0rem;

  &--list {
    padding-left: 1rem;
    margin: 0;
    list-style: none; }

  &--list-item {
    padding: 0.1em 0; }

  &--item-link {
    display: flex; }

  &--list-item-number {
    margin-right: 0.3rem;
    color: var(--body-font-color); }

  &--list-item-title {
    margin-right: 0.3rem; } }
