#project-search-container {
  position: relative;

  project-menu-autocomplete {
    display: block;
    max-height: inherit;

    .project-menu-autocomplete--wrapper {
      display: flex;
      flex-direction: column;
      max-height: inherit; } } }

#project-search-container:nth-child(3) {
  // Substract header height and the two project menu items ("view all", "create new")
  max-height: calc(100vh - var(--header-height) - 2 * 42px); }
#project-search-container:nth-child(2) {
  // Substract header height and the project menu item ("view all")
  max-height: calc(100vh - var(--header-height) - 42px); }

.project-search-results,
.project-search-results.dropdown {
  position: relative;
  overflow: hidden;
  max-height: inherit;
  width: 400px;
  background: white;
  // Avoid scrolling body when autocompleter is at bottom
  // (May not be supported by all browsers yet, but soonish)
  overscroll-behavior: contain;

  @include default-font(var(--header-drop-down-projects-search-font-color), 13px);
  li {
    padding: 0 10px;
    font-size: 14px;

    &:before {
      color: #b3b3b3;
      top: 21px;
      right: 25px;
      font-size: 14px; } }

  // Search input wrapper
  .project-menu-autocomplete--input-container {
    padding: 12px 0;
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: var(--header-drop-down-border-color); }

  // Search input
  input.project-menu-autocomplete--input {
    margin: 0 10px;
    padding: 0px 32px 0px 10px;
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    box-shadow: inset 0px 1px 3px 0px rgba(0,0,0,0.1);
    box-sizing: border-box;
    width: calc(100% - 20px);
    height: 2.125rem;
    background: var(--header-drop-down-projects-search-input-bg-color); }

  // Lens icon on the right
  .project-menu-autocomplete--search-icon {
    position: absolute;
    color: #b3b3b3;
    top: 20px;
    right: 20px;
    font-size: 14px; }

  // Override top menu height
  ul.project-menu-autocomplete--results {
    max-height: 55vh;
    overflow-y: auto;
    // Override the computed width of the input, but span the entire width
    // of the dropdown
    width: 398px !important;
    padding-top: 0px;
    // Borders to complete the menu look
    border-right: 1px solid var(--header-drop-down-border-color);
    border-left: 1px solid var(--header-drop-down-border-color);
    @include styled-scroll-bar; }

  // Cut off result element width
  .ui-menu-item-wrapper {
    @include text-shortener; }

  // Indent the no results pane
  .project-menu-autocomplete--no-results {
    // Mirror border from ui results
    border: 2px solid var(--header-drop-down-border-color);
    border-top: none;
    padding: 12px; } }
