//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2020 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2017 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See docs/COPYRIGHT.rdoc for more details.
//++

@mixin default-transition {
  transition: all 100ms ease-in-out 0s; }

@mixin default-font($color, $font-size: 13px, $font-weight: normal) {
  color:              $color;
  font-family:        var(--body-font-family);
  font-size:          $font-size;
  font-weight:        $font-weight; }

// Make sure an element doesn't collapse due to floated elements inside
@mixin prevent-float-collapse {
  &:after {
    content: " ";
    display: block;
    height: 0;
    clear: both; } }

@mixin text-shortener($oneline: true) {
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  @if $oneline {
    white-space: nowrap; } }


@mixin allow-vertical-scrolling {
  overflow-x: hidden;
  overflow-y: auto; }

@mixin without-link-styling {
  a:link, a:hover, a:active, a:visited {
    color: inherit;
    text-decoration: none; } }

@mixin widget-box--style {
  background: var(--widget-box-block-bg-color);
  margin: 10px;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.1); }

@mixin widget-box--hover-style {
  box-shadow: 0px 1px 20px 0px rgba(0,0,0,0.1); }

// These mixins are necessary so that other classes can inherit     the styles.
// In future Sass versions a doubled inheritance like @extend classA.classB will not work any more.
// See https://github.com/sass/sass/issues/1599
@mixin form--input-field-mixin--tiny {
  font-size: 0.7rem; }

@mixin form--input-field-mixin--small {
  font-size: 0.8rem; }

@mixin form--input-field-mixin--narrow {
  width: auto;
  max-width: 100%; }

@mixin form--input-field-mixin--large {
  font-size: 1.3rem; }

@mixin form--field-affix-mixin--transparent {
  background: none;
  border: none; }


$scrollbar-color: #DDDDDD;
$scrollbar-size: 10px;

@mixin styled-scroll-bar {
  // Firefox specific styles
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;

  // Other browser styles
  &::-webkit-scrollbar {
    height: $scrollbar-size;
    width: $scrollbar-size; }

  &::-webkit-scrollbar-track {
    background: transparent; }

  &::-webkit-scrollbar-thumb {
    background: $scrollbar-color;
    visibility: hidden; }

  &:hover {
    scrollbar-color: $scrollbar-color transparent;
    &::-webkit-scrollbar-thumb {
      visibility: visible; } } }

@mixin no-visible-scroll-bar {
  // Firefox specific styles
  scrollbar-color: transparent transparent;
  scrollbar-width: none;

  // Other browser styles
  &::-webkit-scrollbar {
    height: 0;
    width: 0; }

  &::-webkit-scrollbar-track {
    background: transparent; }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    visibility: hidden; } }

@mixin two-column-layout {
  column-count: 2;
  column-gap: 3rem;

  .attributes-key-value.-column-start {
    column-break-before: always !important;
    break-before: column; }

  @supports (column-span: all) {
        // Let some elements still span both columns
        .attributes-key-value.-span-all-columns {
          column-span: all;
          .attributes-key-value--key {
            flex-basis: calc(22.5% - (4rem / 6)); }
          .attributes-key-value--value-container {
            flex-basis: calc(77.5% + (4rem / 6));
            max-width: calc(77.5% + (4rem / 6)); } } }

  .attributes-key-value {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    // For some reason chrome seems to treat a two column layout
    // as if it would result in showing the backside of this element.
    // This leads to input and select elements not showing their values.
    backface-visibility: visible; } }

@mixin overlay-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 900; }

@mixin extended-content--top {
  #content-wrapper {
    padding-top: 0; } }

@mixin extended-content--bottom {
  #content-wrapper {
    padding-bottom: 0; } }

@mixin extended-content--left {
  #content-wrapper {
    padding-left: 0;

    .toolbar-container {
      padding-left: 20px; }
    .work-packages--filters-optional-container {
      margin-left: 20px; } } }

@mixin extended-content--right {
  #content-wrapper {
    padding-right: 0;

    .toolbar-container {
      padding-right: 15px; }
    .work-packages--filters-optional-container {
      margin-right: 15px; } } }

@mixin modifying--placeholder {
  border: 1px dashed var(--primary-color);
  pointer-events: none;
  * {
    visibility: hidden; } }

@mixin d_n_d--preview {
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1); }

@mixin macro--text-style {
  @media screen {
    // Ensure width of contents is wrapped
    display: inline-block;
    background: rgba(218,223,225,0.19);
    border: 1px solid transparent;
    padding: 2px;

    &:hover {
      cursor: default;
      border-color: #c7c7c7;
      background: rgba(218,223,225,0.75); } } }
