//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2020 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2017 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See docs/COPYRIGHT.rdoc for more details.
//++

.attachments-container {
  float: left;
  margin: 0 0 30px 0;
  width: 100%;
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      margin: 0;
      padding: 0;
      line-height: 20px; } } }

.upload-file {
  display: block;
  width: 100%;
  float: left;
  margin: 20px 0 0 0;
  padding: 20px 0 0 0;
  border-top: 1px solid #ddd; }

.work-package--attachments--drop-box {
  border: 2px dashed var(--light-gray);
  border-radius: 2px;
  text-align: center;
  padding: 20px;
  cursor: pointer;

  &.-dragging {
    background: #f4f4f4; } }


.work-package--attachments--label {
  color: var(--light-gray);
  text-align: center;

  i, label {
    display: inline-block;
    vertical-align: middle; }

  .icon-attachment:before {
    color: var(--light-gray);
    font-size: 3rem; }

  label {
    cursor: pointer;
    color: var(--gray-dark);
    font-size: 0.9rem;
    font-weight: bold;
    line-height: 1.4;
    margin: 0 0 0 10px;
    text-align: left; } }


.add-another-file {
  .button {
    margin-bottom: 0; } }

.work-package--attachments--draggable-item {
  cursor: pointer; }
