span.search-highlight {
  background-color: #FCFD8D;
  &.token-1 {
    background-color: #faa; }
  &.token-2 {
    background-color: #afa; }
  &.token-3 {
    background-color: #aaf; } }


#search-results {
  margin-left: 2em;

  dd {
    padding-left: 30px;
    font-size: 0.9em;

    span.description {
      display: block;
      color: #808080;
      font-style: italic; } } }

div#search-results-counts {
  float: right;
  ul {
    margin-top: 0.5em; }
  li {
    list-style-type: none;
    float: left;
    margin-left: 1em; } }

.search-pagination {
  text-align: center;
  a {
    padding: 0 5px; } }

.top-menu-search--wrapper {
  float: left; }
