/*-- copyright
 * OpenProject Costs Plugin
 *
 * Copyright (C) 2009 - 2015 the OpenProject Foundation (OPF)
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU General Public License
 * version 3.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program; if not, write to the Free Software
 * Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 *
 */
.webhooks--delivery-success {
  color: #019875; }

.webhooks--delivery-error {
  color: #c0392b; }

.webhooks--response-body-modal {
  min-width: 25vw;

  pre {
    background: #f1f1f1;
    padding: 5px; } }


