img.custom-logo-preview,
img.custom-favicon-preview,
img.custom-touch-icon-preview {
  box-shadow: 0 0 3px lightgrey;
  background-color: var(--header-bg-color); }

img.custom-logo-preview {
  height: 42px; }

img.custom-favicon-preview {
  height: 16px;
  background-color: whiteSmoke; }

img.custom-touch-icon-preview {
  height: 180px;
  background-color: whiteSmoke; }
