//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2020 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2017 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See docs/COPYRIGHT.rdoc for more details.
//++

.avatar, .gravatar {
  border-radius: var(--user-avatar-border-radius);
  width: var(--user-avatar-width);
  height: var(--user-avatar-height); }

.avatar-mini {
  border-radius: var(--user-avatar-mini-border-radius);
  width: var(--user-avatar-mini-width);
  height: var(--user-avatar-mini-height);
  margin-right: 10px -5px;

  &.avatar-default {
    line-height: var(--user-avatar-mini-height);
    font-size: 10px; } }

.avatar-medium {
  border-radius: var(--user-avatar-medium-border-radius);
  width: var(--user-avatar-medium-width);
  height: var(--user-avatar-medium-height);

  &.avatar-default {
    line-height: var(--user-avatar-medium-height);
    font-size: 13px;
    vertical-align: inherit; } }

.avatar-default {
  display: inline-block;
  line-height: 34px;
  font-size: 16px;
  text-align: center;
  vertical-align: middle;
  background-color: var(--user-avatar-default-bg-color);
  color: white;
  cursor: inherit;
  user-select: none; }

h1, h2, h3, h4 {
  user-avatar {
    vertical-align: middle;
    margin-right: 5px; } }

tr user-avatar {
  margin-right: 5px; }

.user-link {
  display: inline-block; }

.-spaced {
  &.avatar-medium, &.avatar-mini {
    margin-right: 5px; } }

.user-rate-history-list {
  margin-bottom: 25px; }
