//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2020 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2017 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See docs/COPYRIGHT.rdoc for more details.
//++

.color--preview {
  margin-top: auto;
  margin-bottom: auto;
  border: 1px solid #000;
  border-radius: 4px;
  display: inline-block;
  height: 12px;
  width: 12px;
  &.standalone {
    margin-left: initial;
    vertical-align: middle; } }

.color--text-preview {
  padding: 1px 4px;
  font-size: 0.9rem;
  font-weight: bold; }

.color--milestone-icon,
.color--phase-icon {
  border: 1px solid #000;
  display: inline-block;
  height: 12px;
  width: 12px;
  margin-right: 5px; }

.color--milestone-icon {
  transform: rotate(45deg); }

.color--phase-icon {
  border-radius: 4px; }

.color--preview-patch-field {
  display: flex;
  flex-wrap: wrap; }

.color--preview-patch {
  // Square items
  flex: 0 0 150px;
  height: 150px;
  border: 1px solid #CCCCCC;
  margin: 10px;


  a {
    // Align text centered
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    text-align: center;
    word-break: break-all; }

  a.-bright {
    color: #333333; }

  a.-dark {
    color: white; } }


// -------------------------- Highlighting colors -----------------------------
// Inline: type
[class^='__hl_inline_type'],
[class*=' __hl_inline_type'] {
  text-transform: uppercase;
  font-weight: bold; }

// Inline: all except type
[class^='__hl_inline_']:not([class^='__hl_inline_type']),
[class*=' __hl_inline_']:not([class*='__hl_inline_type']) {
  &::before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    vertical-align: -1px;
    margin-right: 4px;
    border-radius: 50%;

    .-small-font & {
      width: 10px;
      height: 10px; } } }

@mixin dot_border_width_style {
  [class^='__hl_inline_'],
  [class*=' __hl_inline_'] {
    &::before {
      border-width: 1px;
      border-style: solid; } } }
