//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2020 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2017 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See docs/COPYRIGHT.rdoc for more details.
//++

@include breakpoint(680px down) {
  body, html {
    position: relative;
    -webkit-overflow-scrolling: touch;
    height: 100%; }

  // ------------------- BEGIN CHANGED SCROLL BEHAVIOR
  // Change scroll behavior on mobile:
  // Let the window be the one who scrolls.
  // Thus we make sure, that mobile browsers (like iOS Safari) hide their toolbars on scroll.
  html {
    overflow: auto; }
  body,
  #main {
    overflow: visible; }

  #wrapper,
  #content-wrapper,
  #content {
    overflow: hidden; }

  #main {
    margin-top: var(--header-height);

    .-header-scrolled & {
      margin-top: 0; } }

  #content-wrapper {
    height: initial; }
  // ------------------- END CHANGED SCROLL BEHAVIOR

  #content-wrapper {
    padding: 15px; }

  #main {
    grid-template-columns: auto; }

  #breadcrumb,
  .hidden-for-mobile {
    display: none !important; }

  h2 {
    font-size: 1.4rem; } }

@include breakpoint(680px up) {
  .hidden-for-desktop {
    display: none; } }
