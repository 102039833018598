/*-- copyright
 * OpenProject Costs Plugin
 *
 * Copyright (C) 2009 - 2015 the OpenProject Foundation (OPF)
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU General Public License
 * version 3.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program; if not, write to the Free Software
 * Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 *
 */
dt.document:before {
  @include icon-mixin-notes; }

.sidebar--document-sort label:last-of-type {
  margin-bottom: 2rem; }

.document-category--inner-section {
  padding-top: 20px; }

.document-category-elements {
  display: inline; }

.document-category-elements--header {
  margin-bottom: 0.25rem; }

.document-category-elements--date {
  margin-bottom: 0.75rem; }

.document-category-elements .wiki {
  margin-bottom: 2rem; }

