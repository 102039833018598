.wp-table--configuration-modal {
  min-height: 120px;
  max-height: 90vh;

  label.option-label {
    float: left;
    margin-right: 20px;

    &.-multi-line {
      margin-bottom: 0;
      line-height: 40px; }

    input {
      margin-top: 0px; } }

  .modal--form-actions {
    @extend .op-modal--modal-footer; }

  .advanced-filters--filter,
  .advanced-filters--add-filter {
    max-width: 100%; }

  .ee-attribute-highlighting-upsale {
    margin-bottom: 1.5rem; } }
