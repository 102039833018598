.wp-timeline--grid-element {
  position: absolute;
  top: 0;
  padding-top: 5px;
  height: 100%;
  border-right: 1px solid var(--timeline--grid-color);

  &.-grid-highlight {
    border-right: 1px solid var(--timeline--header-border-color); }

  &.grid-weekend {
    background-color: #f5f5f5; } }
