
// Style the hierarchy group indicator arrow
// default: open arrow down
// collapsed: right arrow
.wp-table--hierarchy-indicator {
  color: var(--body-font-color);
  pointer-events: all;

  &:hover {
    text-decoration: none; } }

// Toggle the indicator accessibility texts
// accordingly
.wp-table--hierarchy-indicator-collapsed {
  display: none; }

.-hierarchy-collapsed {
  .wp-table--hierarchy-indicator-expanded {
    display: none; }
  .wp-table--hierarchy-indicator-collapsed {
    display: inline; } }

.wp-table--hierarchy-span {
  text-align: right;
  display: block;
  float: left;
  padding-right: 8px;
  height: 1rem;
 }  // width calculation done in js code

.wp-table--hierarchy-indicator-icon {
  @include icon-common;
  @extend .icon-arrow-down1;
  font-size: 0.6rem;

  .-hierarchy-collapsed & {
    @extend .icon-arrow-right2; } }

.wp--row[class*="__collapsed-group-"] {
  display: none; }

.wp-table--hierarchy-td {
  min-width: 0px !important; }

.hierarchy-header--outer-span {
  white-space: nowrap;
  & > .dropdown-indicator {
    width: 1em;
    text-align: right;
    overflow: visible;
    min-width: 1em;
    visibility: hidden; }

  &:hover > .dropdown-indicator {
    visibility: visible; } }

// Style hierarchy column differently

// Padding for the hierarchy mode
.wp-table--cell-td.subject {
  padding-left: 8px; }

// Highlight additional hierarchy table rows
// that are not part of the normal result list
body {
  .wp-table--hierarchy-aditional-row,
  .wp-table--hierarchy-aditional-row .wp-table--hierarchy-indicator-icon {
    color: var(--table-row-hierarchies-row-font-color); } }

.hierarchy-header--icon {
  cursor: pointer;
  display: inline-block;
  flex: 0 0 0;
  width: 20px;

  i:before {
    padding: 0;
    // Align the botched verticality of the hierarchy icons.
    vertical-align: middle;
    font-size: 20px;
    color: var(--body-font-color); } }
